.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.title {
  font-family: 'TT Marxiana Antiqua Italic'; 
  font-size: 3.2rem; 
}

.subtitle {
  font-family: 'TT Marxiana Antiqua'; 
  font-size: 1.7rem; 
  margin-top: -15px;   
  margin-bottom: 15px;   
}

.header-nodesc {
  height: 50px;
  z-index: 1;
}

.two-header {
  font-size: 34px;
}

.weeks-header {
  font-size: 32px;
}

.short-header {
  font-size: 18px;
}

.greetings {
  font-size: 30px;
}

.story-container {
  padding: 10px 40px;
  margin: 30px auto 20px auto;
  max-width: 800px;
  max-height: 1000px;
  width: 100%;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden; 
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  direction: rtl;
}

.sound-toggle {
  position: absolute; 
  bottom: 5%;
  left: 20%; 
  height: 100px;
  width: 90px; 
}

.next {
  margin-left: 75px;
}
.prev {
  margin-right: 75px;
}

.prevButton, .nextButton {
  width: 60px;
  height: 60px;
}

.disclaimer {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 2px;
}

.nameGen {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 2px;
}

.error-msg {
  color: red; 
  text-align: center; 
  font-size: 15px;
}

.hallway-img {
  width: 100%; 
  height: auto; 
}

@media (max-width: 600px) {
  .greetings {
    font-size: 22px;
  }
}

@media (min-width: 650px) and  (min-height: 1000px) {
  .sound-toggle {
    bottom: 2%;
    left: 5%;
  }

  .story-container {
    width: 550px; 
    height: 930px;
  }
}

@media (min-width: 650px) and (max-height: 1150px) and (min-height: 1100px) {
  .sound-toggle {
    bottom: 2%;
    left: 5%;
  }

  .story-container {
    width: 550px; 
    height: 870px;
  }
}

@media (min-width: 650px) and (max-height: 1100px) and (min-height: 1050px) {
  .sound-toggle {
    bottom: 2%;
    left: 5%;
  }

  .story-container {
    width: 550px; 
    height: 800px;
  }
}

@media (min-width: 650px) and (max-height: 1050px) and (min-height: 940px) {
  .sound-toggle {
    bottom: 2%;
    left: 5%;
  }

  .story-container {
    width: 550px; 
    height: 700px;
  }
}

@media (min-width: 650px) and (max-height: 940px) and (min-height: 850px) {
  .sound-toggle {
    bottom: 2%;
    left: 5%;
  }

  .story-container {
    width: 550px; 
    height: 600px;
  }
}

@media (min-width: 650px) and (max-height: 850px) and (min-height: 745px) {
  .sound-toggle {
    bottom: 2%;
    left: 5%;
  }

  .story-container {
    width: 550px; 
    height: 500px;
  }
}

@media (min-width: 650px) and (max-height: 745px) and (min-height: 640px) {
  .sound-toggle {
    bottom: 2%;
    left: 5%;
  }

  .story-container {
    width: 550px; 
    height: 400px;
  }
}

@media (min-width: 650px) and (max-height: 640px) and (min-height: 535px) {
  .sound-toggle {
    bottom: 2%;
    left: 5%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 550px; 
    height: 300px;
  }
}

@media (max-width: 650px) and (min-width: 550px) and (min-height: 1150px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 400px; 
    height: 930px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 650px) and (min-width: 550px) and (min-height: 1075px) and (max-height: 1150px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 400px; 
    height: 850px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 650px) and (min-width: 550px) and (min-height: 1000px) and (max-height: 1075px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 400px; 
    height: 750px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 650px) and (min-width: 550px) and (min-height: 950px) and (max-height: 1000px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 400px; 
    height: 750px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 650px) and (min-width: 550px) and (min-height: 850px) and (max-height: 950px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 400px; 
    height: 650px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 650px) and (min-width: 550px) and (min-height: 725px) and (max-height: 850px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 400px; 
    height: 550px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 650px) and (min-width: 550px) and (min-height: 625px) and (max-height: 725px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 400px; 
    height: 450px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 650px) and (min-width: 550px) and (min-height: 525px) and (max-height: 625px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 400px; 
    height: 350px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 550px) and (min-width: 460px) and (min-height: 1150px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 350px; 
    height: 930px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 550px) and (min-width: 460px) and (min-height: 1075px) and (max-height: 1150px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 350px; 
    height: 850px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 550px) and (min-width: 460px) and (min-height: 1000px) and (max-height: 1075px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 350px; 
    height: 750px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 550px) and (min-width: 460px) and (min-height: 950px) and (max-height: 1000px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 350px; 
    height: 750px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 550px) and (min-width: 460px) and (min-height: 850px) and (max-height: 950px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 350px; 
    height: 650px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 550px) and (min-width: 460px) and (min-height: 725px) and (max-height: 850px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 350px; 
    height: 550px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 550px) and (min-width: 460px) and (min-height: 625px) and (max-height: 725px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 350px; 
    height: 450px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 550px) and (min-width: 460px) and (min-height: 525px) and (max-height: 625px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 350px; 
    height: 350px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}
@media (max-width: 460px) and (min-width: 360px) and (min-height: 1150px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 250px; 
    height: 930px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }

}

@media (max-width: 460px) and (min-width: 360px) and (min-height: 1075px) and (max-height: 1150px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 250px; 
    height: 850px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }

}

@media (max-width: 460px) and (min-width: 360px) and (min-height: 1000px) and (max-height: 1075px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 250px; 
    height: 750px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 460px) and (min-width: 360px) and (min-height: 950px) and (max-height: 1000px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 250px; 
    height: 750px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 460px) and (min-width: 360px) and (min-height: 850px) and (max-height: 950px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 250px; 
    height: 650px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 460px) and (min-width: 360px) and (min-height: 725px) and (max-height: 850px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 250px; 
    height: 550px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 460px) and (min-width: 360px) and (min-height: 625px) and (max-height: 725px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 250px; 
    height: 450px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}

@media (max-width: 460px) and (min-width: 360px) and (min-height: 525px) and (max-height: 625px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 250px; 
    height: 350px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 75px;
  }
}


@media (max-width: 360px) and (min-width: 260px) and (min-height: 1150px) {
  .sound-toggle {
    bottom: 3%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 200px; 
    height: 930px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 25px;
  }
}

@media (max-width: 360px) and (min-width: 260px) and (min-height: 1075px) and (max-height: 1150px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 200px; 
    height: 850px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 25px;
  }
}

@media (max-width: 360px) and (min-width: 260px) and (min-height: 1000px) and (max-height: 1075px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 200px; 
    height: 750px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 25px;
  }
}

@media (max-width: 360px) and (min-width: 260px) and (min-height: 950px) and (max-height: 1000px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 200px; 
    height: 750px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 25px;
  }
}

@media (max-width: 360px) and (min-width: 260px) and (min-height: 850px) and (max-height: 950px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 200px; 
    height: 650px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 25px;
  }
}

@media (max-width: 360px) and (min-width: 260px) and (min-height: 725px) and (max-height: 850px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 200px; 
    height: 550px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 25px;
  }
}

@media (max-width: 360px) and (min-width: 260px) and (min-height: 625px) and (max-height: 725px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 200px; 
    height: 450px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 25px;
  }
}

@media (max-width: 360px) and (min-width: 260px) and (min-height: 525px) and (max-height: 625px) {
  .sound-toggle {
    bottom: 2%;
    left: 6%;
    width: 70px;
    height: 80px;
  }

  .story-container {
    width: 200px; 
    height: 350px;
  }

  .prev {
    margin-right: 0;
    margin-left: 75px;
  }
  
  .next {
    margin-right: 0;
    margin-left: 25px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(216, 216, 216);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.story-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  padding: 20px;
}

.story-container * {
  direction: ltr;
}

.story-container::-webkit-scrollbar {
  width: 20px;
}

.story-container::-webkit-scrollbar-thumb {
  background-color: #f4e3cb;
  border-radius: 10px;
}

.story-container::-webkit-scrollbar-track {
  background-color: #cccaca;
}

.story-container::-webkit-scrollbar-thumb:hover {
  background-color: #8c8a8a;
}

.story-item {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  background-color: white;
  width: 100%;
}

.story-item h3 {
  margin-bottom: 10px;
  font-size: 22px;
}

.story-item p {
  white-space: pre-wrap;
  margin: 0;
  text-indent: 0;
  text-align: justify;
  font: 400 18px/1.6 'Alegreya Regular', serif;
  justify-content: center;
}

.options {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.option-button {
  font-size: 18px;
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  cursor: pointer;
}

.option-button:hover {
  background-color: #ddd;
}

.header-container{
  text-align: center,
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: gray;
  animation: spin 0.5s ease infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



@font-face {
  font-family: 'TT Marxiana Grotesque';
  src: url('./assets/fonts/TTMarxiana-Grotesque.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Marxiana Antiqua';
  src: url('./assets/fonts/TTMarxiana-Antiqua.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Marxiana Elzevir';
  src: url('./assets/fonts/TTMarxiana-Elzevir.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Marxiana Antiqua Italic';
  src: url('./assets/fonts/TTMarxiana-AntiquaItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Alegreya Regular';
  src: url('./assets/fonts/Alegreya-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Alegreya Italic';
  src: url('./assets/fonts/Alegreya-Italic.otf') format('opentype');
}